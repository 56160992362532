import React from 'react';
import { Card, Col } from 'react-bootstrap';

const TarjetaPropiedad = ({ propiedad }) => {
  return (
    <Col md={4} className="mb-4">
      <Card>
        <Card.Img
          variant="top"
          src={propiedad.image ? propiedad.image : 'default-image.jpg'}
          alt={propiedad.title}
        />
        <Card.Body>
          <Card.Title>{propiedad.title}</Card.Title>
          <Card.Text>
            <strong>Ubicación:</strong> {propiedad.location}
          </Card.Text>
          <Card.Text>
            <strong>Precio:</strong> ${propiedad.price} / {propiedad.period}
          </Card.Text>
          <Card.Text>
            <strong>Camas:</strong> {propiedad.beds} - <strong>Baños:</strong> {propiedad.baths}
          </Card.Text>
          <Card.Text>
            <strong>Superficie:</strong> {propiedad.size} m²
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TarjetaPropiedad;
