import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import TarjetaPropiedad from './TarjetaPropiedad'; // Importamos el componente de la tarjeta

const PropiedadesDestacadas = () => {
  const [propiedades, setPropiedades] = useState([]); // Estado para almacenar propiedades
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de errores

  // Datos estáticos para simular la respuesta de una API
  const propiedadesEjemplo = [
    {
      id: 1,
      title: 'Bungalow de 2 habitaciones en alquiler',
      location: 'Hang Dong, Phrae',
      price: 200,
      period: 'mes',
      beds: 2,
      baths: 3,
      size: 250,
      image: 'https://via.placeholder.com/300x200', // Puedes usar un enlace de imagen de ejemplo
    },
    {
      id: 2,
      title: 'Villa moderna con piscina en venta',
      location: 'Suthep, Mueang Chiang',
      price: 2500,
      period: 'año',
      beds: 3,
      baths: 3,
      size: 350,
      image: 'https://via.placeholder.com/300x200',
    },
    {
      id: 3,
      title: 'Casa de lujo moderna con piscina en alquiler',
      location: 'Mae Rim, Mae Sa',
      price: 250,
      period: 'mes',
      beds: 2,
      baths: 3,
      size: 250,
      image: 'https://via.placeholder.com/300x200',
    },
  ];

  // Simulamos una carga de datos
  useEffect(() => {
    setLoading(true);
    try {
      // Simulamos un pequeño retraso para mostrar el estado de carga
      setTimeout(() => {
        setPropiedades(propiedadesEjemplo); // Asignamos los datos estáticos
        setLoading(false);
      }, 1000);
    } catch (error) {
      setError('Error al cargar propiedades');
      setLoading(false);
    }
  }, []);

  // Manejo del estado de carga y error
  if (loading) return <p>Cargando propiedades...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Row className="m-4">
      {propiedades.map((propiedad) => (
        <TarjetaPropiedad key={propiedad.id} propiedad={propiedad} />
      ))}
    </Row>
  );
};

export default PropiedadesDestacadas;
